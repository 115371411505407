import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt.js"
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact = ({ data }) => {
    return(
        <Layout>

        <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Send us a message if you want Bible Studies" />
        <title>ReaCH | Contact Us</title>
        <link rel="canonical" href="https://reachun.org/contact" />
      </Helmet>

            <section className="sm:w-3/4  sm:ml-1/8 mt-20 mb-20 xs:p-4 sm:p-0">
            <div className="font-montserrat">
              <h1 className="font-bold text-2xl text-blue-dark">{data.strapiContact.title}</h1>
            
            </div>
            <div>
                <h2 className="font-bold text-2xl text-blue-dark mt-10">{data.strapiContact.info.title}</h2>
                <p className="font-opensans text-lg tracking-wide mt-2">{data.strapiContact.info.description}</p>
                <p className="font-playfairdisplay font-bold text-4xl text-gray-600 tracking-wide leading-snug mt-4">{data.strapiContact.info.content}</p>
            </div>

                
                        

            <div className="grid grid-col-1 xl:grid-cols-7 gap-2 my-20">
              <div className="xl:col-start-2 xl:col-span-5 shadow-md xs:px-4 sm:px-12 pb-12">

              <div className="border-b border-gray-200 pb-4  my-10">
                           <p className="font-montserrat font-bold text-3xl text-blue-dark">Contact  Information</p> 
                            </div>

                    <div className=""> 
                          
                            {data.strapiContact.contactcard.numbers.map(num =>(
                                <div key={num.id} className="flex items-center mb-6">
                                <p className="mr-2 flex justify-center items-center rounded-lg"><FaPhone size="20" className="text-gray-700"/></p>
                                <p className="pl-6 font-opensans text-xl text-gray-700"> {num.number}</p>
                                </div> 
                            ))}
                    </div>
                    <div>
                            {data.strapiContact.contactcard.emails.map(mail =>(
                                <div key={mail.id} className="flex items-center mb-6">
                                <p className="mr-2  flex justify-center items-center rounded-lg"><FaEnvelope size="20" className="text-gray-700"/></p>
                                <p className="pl-6 font-opensans text-xl text-gray-700">{mail.email}</p>
                                </div> 
                            ))}
                    </div>
                    <div>
                            {data.strapiContact.contactcard.addresses.map(loc =>(
                                        <div key={loc.id} className="flex items-center mb-2">
                                        <p className="mr-2  flex justify-center items-center rounded-lg"><FaMapMarkerAlt size="24" className="text-gray-700"/></p>
                                        <p className="pl-6 font-opensans text-xl text-gray-700">{loc.address}</p>
                                        </div> 
                            ))}
                    </div>
              </div>
            </div>

            <div id="contact" className="grid grid-cols-1 xl:grid-cols-7 gap-2 ">
               <div className="xl:col-start-2 xl:col-span-5 shadow-md xs:px-4 sm:px-12 pb-12">

               <div className="border-b border-gray-200 pb-4  my-10">
                           <p className="font-montserrat font-bold text-3xl text-blue-dark">Send Us a Message</p> 
                            </div>
                  <form 
                  action="/thankyou" 
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot">
                     
                     <input type="hidden" name="form-name" value="contact" />
                    <div className="invisible">
                      <label>Don't Fill this out, human</label>
                      <input name="bot" />
                    </div>

                     {data.strapiContact.contactform.placeholder.map(pl =>(
                        <div key={pl.id}>
                        <input id={pl.text} name={pl.text} placeholder={pl.text} className="px-2 py-4  w-full rounded-lg mb-2 border border-gray-300"/>
                      </div> 
                     ))}
                     <div>
                        <textarea 
                        id={data.strapiContact.contactform.message.text}
                        name={data.strapiContact.contactform.message.text}
                        placeholder={data.strapiContact.contactform.message.text}
                        className="px-2 py-4 x w-full h-48 rounded-lg mb-2 border border-gray-300"
                        />
                     </div>
                     <div>
                        
                        <input type="submit" value="Submit" className="cursor-pointer font-opensans font-bold text-white bg-orange-dark px-2 py-2 w-48 rounded-lg mb-2"/>
                      
                     </div> 
                </form>
                </div>
              </div>
            </section>
            <Social />
        </Layout>
    )
}
export const query = graphql`
    query ContactPageQuery{
        strapiContact {
            title
            description
            info {
                id
                title
                description
                content
              }
            contactcard {
              numbers {
                number
                icon
                id
              }
              emails {
                email
                icon
                id
              }
              addresses {
                address
                icon
                id
              }
            }
            contactform {
              placeholder {
                id
                text
              }
              submitbutton {
                id
                path
                title
                icon
              }
              message {
                id
                text
              }
            }
          }
    }
`
export default Contact
